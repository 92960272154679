<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>

  <div class="top-header top-header-dark2" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="header-contact">
            <ul class="d-flex justify-content-between">
              <li class="text-white">
                <i class="fa fa-phone" aria-hidden="true"></i
                ><span class="fw-bold">Call Us :</span>
                +44 7398955333 | +44 07922241113 | 02036599067
              </li>

              <li>
                <div class="text-end">
                  <a
                    routerLink="/pages/contact"
                    href="javascrip:void(0)"
                    class="btn btn-black-get-free-quote m-0 rounded-pill"
                  >
                    Get a free quote
                  </a>
                </div>
              </li>
              <li class="text-white">
                <i class="fa fa-envelope"></i>
                <span class="fw-bold"> Email Us : </span>
                Teddingtonglazing@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container layout3-menu">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu px-4">
          <div class="position-relative">
            <a [routerLink]="['/home/fashion']">
              <img
                class="p-1 tg-logo"
                src="../../../../assets/images/teddingon-svg.svg"
                alt="logo"
                width="70px"
                height="70px"
              />
            </a>
          </div>

          <div class="menu-left around-border">
            <div class="main-menu-right">
              <app-menu></app-menu>
            </div>
          </div>
          <div class="button">
            <div class="menu-right pull-right">
              <div class="text-end">
                <a
                  target="_blank"
                  href="https://doordesigner.app/dcdf/#"
                  class="btn btn-solid m-0 rounded-pill"
                >
                  Design a door
                </a>
              </div>
            </div>
          </div>
          <div class="button">
            <div class="menu-right pull-right">
              <!-- <div class="text-end">
                <a
                  routerLink="/pages/contact"
                  href="javascrip:void(0)"
                  class="btn btn-solid m-0 rounded-pill"
                >
                  Get a free quote
                </a>
              </div> -->

              <img
                class="p-1 tg-logo"
                src="../../../../assets/images/logos/WhatsApp Image 2024-10-14 at 4.54.11 AM.jpeg"
                alt="logo"
                width="70px"
                height="70px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
